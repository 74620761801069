input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not(
    [type="button"]
  ).ng-touched,
.show-errors
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not(
    [type="button"]
  ),
select.ng-touched,
.show-errors select {
  &.ng-valid {
    border-left: 5px solid $success;
    /* green */
  }

  &.ng-invalid:not(form) {
    border-left: 5px solid $danger;
    /* red */
  }
}
