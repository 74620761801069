/*
Noir : #262626          (RVB -> R: 38 ; V: 38 ; B: 38           85% pour Word et compagnie ; )
Bleu: #003D67         (RVB -> R: 0 ; V:  61 ; B: 103)
Orange : #F09000  (RVB -> R: 240 ; V: 144 ; B: 0)
*/
$success: #a1db34;
$primary: #003d67;
$secondary: #f09000;
$light: #f0f0f0;
$danger: #d82929;
