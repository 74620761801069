@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

$body-color: #262626;

@import "_colors_vars.scss";

$custom-theme-colors: (
  primary: $primary,
  success: $success,
  secondary: $secondary,
  light: $light,
  danger: $danger,
);

$theme-colors: map-merge($theme-colors, $custom-theme-colors);
/*
/* we should do it like that but it seem not to work (removes bg-white)
$custom-theme-colors: map-merge(
  $theme-colors,
  (
    primary: $primary,
    success: $success,
    secondary: $secondary,
    dark: $dark,
    medium: $medium,
    light: $light,
    light-30p: $light-30p,
    warning: $warning,
    danger: $danger,
    info: $info,
  )
);

$theme-colors: map-merge($theme-colors, $custom-theme-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop($utilities-colors, rgba-css-var, '$key', 'text');
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, '$key', 'bg');
*/
