/*
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/typography/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/typography/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/typography/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/typography/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/typography/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
*/
$font-family: 'Muli',
Arial,
sans-serif;

* {
  font-family: $font-family;
  font-weight: normal;
}

::-webkit-input-placeholder {
  font-family: $font-family;
}

:-ms-input-placeholder {
  font-family: $font-family;
}

:-moz-placeholder {
  font-family: $font-family;
}

::-moz-placeholder {
  font-family: $font-family;
}

th {
  font-weight: bold;
}

p,
a,
u,
i,
h1,
h2,
h3,
h4,
h5,
label,
button,
input {
  &.thin {
    font-weight: 200;
  }

  &.light {
    font-weight: 300;
  }

  &.semi-light {
    font-weight: 400;
  }

  &.normal {
    font-weight: normal;
  }

  &.medium {
    font-weight: 600;
  }

  &.bold {
    font-weight: bold;
  }
}
