@import "bootstrap_init";

@import "../../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "../../../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "../../../../node_modules/@fortawesome/fontawesome-free/scss/regular.scss";
@import "../../../../node_modules/@fortawesome/fontawesome-free/scss/brands.scss";

@import "forms";
@import "spin";
@import "typography";

html,
body {
  height: 100%;
  color: #262626;
}

/*
.btn-round {
  border-radius: 28px !important;
  border-width: 2px;
  letter-spacing: 0.5px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.btn {
  padding-left: 1rem;
  padding-right: 1rem;
  letter-spacing: 0.8px;
  border-radius: 6px;
}

.btn-sm {
  padding: 0.15rem 0.5rem;
}
*/
.pointer {
  cursor: pointer;
}

.no-select {
  user-select: none;
}

.form-control {
  border-radius: unset;
}

.input-group-text {
  border-radius: unset;
  border-bottom-right-radius: unset;
  border-bottom-left-radius: unset;
}

.form-control:focus {
  border: 2px solid $secondary;
  box-shadow: none;
}

a.disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.white {
  color: white;
}

a {
  color: #006ab2;
}

.grecaptcha-badge {
  display: none;
}
